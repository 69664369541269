import MainHeader from './MainHeader'
import MainNavigation from "./MainNavigation";
import Footer from './Footer'
import classes from './Layout.module.css'

function Layout({ children}) {
    return (
        <>
            <MainHeader />
            <MainNavigation />
            <main className={classes.main}>{children}</main>
            <Footer />
        </>
    );
}

export default Layout;