import classes from './Footer.module.css';

function Footer() {
    return (
        <div className={classes.footer}>
            <div className={classes.footerText}>Powered by LazTech</div>
        </div>
    );
}

export default Footer;