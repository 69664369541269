import classes from './Home.module.css' 
import airobot from '../ai-robot.png'

function Home() {

    let content = (
        <div className={classes.homeContent}>
            <div className={classes.aiRobotDiv}>
                <img className={classes.aiRobotImg} alt="" src={airobot} ></img>
            </div>
            <div className={classes.textContent}>
                <div className={classes.textParagraph}>
                    Our investment is YOU!
                </div>
                <div className={classes.textParagraph}>
                    At LazTech Solutions, we believe in the value and significance of the small business.  
                    It is our mission to help the small business be as productive and efficient as possible.
                    We offer simple yet elegant automated solutions for your everyday tasks and workflows. 
                </div>
                <div className={`${classes.textParagraph} ${classes.marginTop2em}`}>
                    Let us help YOU improve your business.
                </div>
            </div>
        </div>
    )

    return (
        <div>
            {content}
        </div>
    ); 
}

export default Home;