import classes from './MainHeader.module.css';

function MainHeader() {
    return (
        <div className={classes.header}>
            <p>LazTech Solutions</p>
        </div>
    );
}

export default MainHeader;