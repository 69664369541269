import { BrowserRouter, Routes, Route } from 'react-router-dom';

import classes from './App.css'
import * as routeLinks from './routes/routes';
import Layout from './components/shared/Layout';
import Home from './pages/Home';
import Contact from './pages/Contact';

//import logo from './logo.svg';
import logo from './laztech_logo.png';
//import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Layout className={classes.fullHeight}>
        <Routes>
          <Route path={routeLinks.rootRoute} element={<Home />} />
          <Route path={routeLinks.contactRoute} element={<Contact />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
