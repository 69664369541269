import classes from './Contact.module.css'

let content = (
    <div className={classes.contactContent}>
        <div className={`${classes.textContentParagraph} ${classes.boldFont} `}>
            Raymond Lazarus 
        </div>
        <div className={classes.textContentParagraphPhone}>
            (405) 613-8519 
        </div>
        <div className={`${classes.textContentParagraph} ${classes.boldFont} `}>
            Amy Lazarus 
        </div>
        <div className={classes.textContentParagraphPhone}>
            (405) 623-2672 
        </div>
        <div className={`${classes.textContentParagraph} ${classes.marginTop1em} `}>
            <div className={`${classes.textContentParagraph} ${classes.boldFont}`}>
                Email: 
            </div>
            <div className={classes.textContentParagraphPhone}>
                maclazarus1@gmail.com 
            </div>
            <div className={classes.textContentParagraphPhone}>
                amylazarus85@gmail.com 
            </div>
        </div>

    </div>

);

function Contact() {
    return (
        <div>
            {content}
        </div>
    );
}

export default Contact;